import { Provider } from 'react-redux';
import Loadable from 'react-loadable';
import { Router, Switch, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale-provider/zh_CN';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import store from 'services/store';
import history from 'services/history';
import Lodaing from 'components/Loading';
import RouterQuery from 'components/RouterQuery';

Sentry.init({
  enabled: process.env.REACT_APP_SENTRY_DSN !== undefined && process.env.NODE_ENV === 'production',
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_VERSION,
  environment: process.env.REACT_APP_DEPLOY_ENV,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE) || 0.01,
});

const Main = Loadable({
  loader: () => import('views/Main'),
  loading: Lodaing,
});
const Forbidden = Loadable({
  loader: () => import('views/Forbidden'),
  loading: Lodaing,
});
const Login = Loadable({
  loader: () => import('views/Login'),
  loading: Lodaing,
});

function App() {
  return (
    <ConfigProvider locale={locale}>
      <Provider store={store}>
        <Router history={history}>
          <RouterQuery>
            <Switch>
              <Route path="/403" component={Forbidden} />
              <Route path="/login" component={Login} />
              <Route path="/" component={Main} />
            </Switch>
          </RouterQuery>
        </Router>
      </Provider>
    </ConfigProvider>
  );
}

export default App;

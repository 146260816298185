import AxiosClient from 'services/utils/AxiosClient';
import HeaderAuth from 'services/utils/HeaderAuth';
import withAuth from 'services/utils/withAuth';

const AUTH_HEADER_NAME = process.env.REACT_APP_AUTH_HEADER_NAME;
const headerAuth = new HeaderAuth(AUTH_HEADER_NAME, '');
const CENTER_API_ENDPOINT = process.env.REACT_APP_CENTER_API_ENDPOINT;
const AuthClient = withAuth(headerAuth)(AxiosClient);

export const centerClient = new AuthClient({
  baseURL: CENTER_API_ENDPOINT,
});

export function authorize(token) {
  centerClient.authorize(token);
}

/* eslint-disable no-console */

import { Modal, notification } from 'antd';

import { unauthorize } from 'services/store/profile/effects';

let unauthorizeModalVisible = false;

export function createError(err, type, meta) {
  return {
    type: type ? `@@ERROR/${type}` : '@@ERROR',
    payload: err,
    error: true,
    ...(meta
      ? {
          meta,
        }
      : {}),
  };
}

function errorHandle(store) {
  return (next) => (action) => {
    const {
      payload = {},
      meta: { type = 'error', message, description, duration } = {},
      error,
    } = action;

    if (error) {
      const timestamp = Date.now();
      const state = store.getState();
      console.group('Handle Error');
      console.info('timestamp:', timestamp);
      console.info('   action:', action);
      console.info('    state:', state);
      console.error(payload);
      console.groupEnd();

      let config;

      if (message || description) {
        config = {
          type,
          message,
          description,
          duration,
        };
      }

      if (payload.isApiError) {
        const { status, data } = payload.response || {};

        if (status === 401) {
          if (!unauthorizeModalVisible) {
            unauthorizeModalVisible = true;

            Modal.error({
              title: '会话已过期',
              content: '会话已过期，请重新登录',
              onOk() {
                store.dispatch(unauthorize());

                unauthorizeModalVisible = false;
              },
            });
          }

          config = null;
        } else {
          if (!config) {
            config = {
              type: 'error',
              duration: 3,
            };
          }

          switch (status) {
            case 400: {
              config.message = config.message || '参数错误';
              config.description = config.description || '参数错误，请检查参数是否正确';
              break;
            }
            case 404: {
              config.message = config.message || '资源不存在';
              config.description = config.description || '资源不存在，该资源可能已经被移除';
              break;
            }
            case 500: {
              config.message = config.message || '服务器错误';
              config.description = config.description || '服务器遇到了未知错误，请稍后再试';
              break;
            }
            default: {
              config.message = config.message || '未知错误';

              if (!config.description) {
                if (data) {
                  const { message = data } = data;

                  config.description =
                    typeof message === 'string' ? message : JSON.stringify(data, null, 2);
                } else {
                  config.description = payload.message;
                }
              }
            }
          }
        }
      }

      if (config) {
        notification.open(config);
      }
    }

    next(action);
  };
}

export default errorHandle;
